.bg-blur-nav {
    background: #0d0e14;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1 !important; /* this line added to show side menu drawer fully */
    padding: 5px 10px !important;
}

.navbarlink {
    color: #ffffff;
    margin-right: 25px;
    font-weight: 700 !important;
    text-decoration: none;
}

.title-text {
    color: #ffffff;
    margin-left: 10px !important;
    font-weight: 500;
    letter-spacing: 2px !important;
}
.menu-change-icon {
    color: #5b5959;
    margin-left: 15px !important;
    font-weight: 400;
}

.navbarlink:hover {
    color: #f4d291;
    border-bottom: 1px solid #f4d291;
    padding: 10px 0px;
    transition: 0.2s;
}

@media only screen and (max-width: 1000px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media only screen and (min-width: 1000px) {
    .mobile-menu {
        display: none !important;
    }
    .hide-on-pc {
        display: none !important;
    }
}
.ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item, :where(.css-dev-only-do-not-override-1swqovi).ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 24px;
    transition: all 0.3s;
}
.disconnect-button {
    color: #009edd !important;
}
.mobile-menu {
    margin-top: -3px;
    margin-left: 4px;
  }

  .rfc-main-logo {
    width: 40px;
  }
  
  @media (max-width: 340px){
    .rfc-main-logo {
      width: 40px;
    }
  }
  .affix-container {
    background: #101420;
  }
  .ant-menu-dar {
    color: rgba(255, 255, 255, 0.85);
    background: #f20101;
}
.ant-menu-dark {
    background: #0d0e14 !important;
    font-size: 18px;
    align-items: flex-end !important;
}
@media (max-width: 768px){
    .hide-on-small-devices {
      display: none !important;
    }
}
.menu-dropdown {
    padding: 10px !important;
}
.menu-dropdown-topics {
    padding: 10px !important;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
}
.menu-dropdown-topics:hover {
    font-weight: 600;
}
.menu-text-button {
    font-size: 15px;
}
.coins-title {
    font-weight: 100;
    letter-spacing: 2px !important;
    margin-left: 2px !important;
}