body {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
* {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  }

.background-color {
    /* background: rgba(28,29,48, 255); */
    background-image: repeating-linear-gradient(to right, #12141D 0%,  #31318e 50%,#12141D 100%);
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.rfc-primary-button {
    background: linear-gradient(90deg, #2e62ff, #4b24dc) !important;
    border: none !important;
    color: #fff !important;
}

.rfc-primary-button:hover {
    background: linear-gradient(180deg, #2e62ff, #4b24dc) !important;
    border: none !important;
    color: #fff !important;
}

/* center a div */
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.rfc-default {
    background: #1a2237 !important;
    border: none !important;
}

.rfc-button-card {
    background: #101420 !important;
    border: none !important;
    padding: 15px;
}
@media (max-width: 768px) {
    .rfc-button-card {
        padding: 2px !important;
    }
}

.card-border-wrap {
    position: relative;
    padding: 2px;
    border-radius: 10px;
    background: #1c1d30;
}

.card-border-wrap:hover {
    position: relative;
    padding: 2px;
    border-radius: 10px;
    background: linear-gradient(90.64deg,#b160f0,#818cf6 48.31%,#009edd 98.68%);
}
a {
    color: #d0b67d;
    text-decoration: none;
}

@media (max-width: 1000px) {
    .side-menu {
        display: none !important;
    }
    .side-menu-margin-extended {
        margin: 0 !important;
        /* display: none; */
    }
}
.side-menu-margin-extended {
    margin-left: 240px;
    transition: 0.5s;
}
.side-menu-margin {
    margin-left: 80px;
    transition: 0.5s;
}
.content-margin {
    margin-top: 60px !important;
}
.side-menu {
    background-color: #0d0e14;
    /* width: 13% !important; */
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #009edd;
}
.ant-tabs .ant-tabs-ink-bar {
    background: #009edd;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #b160f0 #009edd;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 4px;
    /* margin-bottom: 25px; */
  }
  
  *::-webkit-scrollbar-track {
    background: #b160f0;
    margin-bottom: 1px;
    margin-top: 4px;
    border-radius: 15px;
  }
  .menu-background {
    background: #0d0e15 !important;
    margin-top: 84px;
}
.sider-background {
    background: #0d0e15 !important;
}
.ant-layout .ant-layout-sider-children {
    background: #0d0e15 !important;
}
.ant-layout {
    background: #0d0e15 !important;
}
.ant-drawer .ant-drawer-body {
    background: #0d0e15 !important;
} 
a {
    color: #b160f0 !important;
}
* {
    letter-spacing: 1px !important;
}