.sider{
    line-height: 120px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #31339077 !important;
    border-radius: 1px !important;
    border-right: 5px solid #b160f0 !important;
}
.ant-menu {
    letter-spacing: 2px !important;
    font-weight: 300;
}