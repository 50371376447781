.page-topic {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.1;
}

.text-validation-error {
    font-size: 0.75rem;
    color: #f45875;
}

.required-field-warning {
    color: #f45875;
}

.fee-detail-text {
    font-size: 0.75rem;
    /* margin-top: 0.25rem; */
    color: #3298dc;
}

.is-danger-text {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    color: #f14668;
}

.airdrop-list-header-number {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
}

.airdrop-list-topic {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 2px;
    /* background-color: linear-gradient(90.64deg,#b160f0,#818cf6 48.31%,#009edd 98.68%) !important; */
    /* color: #b160f0; */
}

.detail-title {
    font-size: 26px;
    font-weight: 600;
    margin-left: 20px;
}

.detail-view-card {
    border: solid 1px #3c3a3a;
    padding: 10px 15px;
    border-radius: 10px;
    background: #41417940;
}

.card-logo {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    border: 1px solid #b160f0 !important;
    background: #141414;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* padding: 10px; */
    /* margin-top: -50px; */
    margin-bottom: 15px;
    /* box-shadow: 0 0 15px 3px rgba(230, 190, 79, 0.5), 0 6px 30px 0 rgba(230, 190, 79, 0.5); */
    object-fit: cover;
    overflow: hidden;
}

.ant-form-item {
    margin-bottom: 2px !important;
}

.social-links-pool-profile {
    margin-right: 8px;
}

.need-tokens {
    font-size: 12px;
    color: #3298dc;
}

.bottom-divider {
    border-bottom: 1px solid rgba(136, 126, 107, 0.55);
    margin-top: 5px !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    font-size: 12px;
}

.token-detail-description {
    font-size: 13px;
}

.progress-bar-values {
    font-size: 10px;
    line-height: 1px;
}

.contribution-details {
    font-size: 13px;
}