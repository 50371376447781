/* .tawln-wrapper {
    border: 1px solid #b260f085 !important;
    border-radius: 10px 0px 0px 10px !important;
    font-weight: 300 !important;
    font-size: 15px !important;
}
.source {
    border-radius: 0px 10px 0px 0px !important;
    border: 1px solid #b260f085 !important;
    font-weight: 300 !important;
    font-size: 15px !important;
} */
.tawln-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.45) !important;
  border-radius: 10px 0px 0px 10px !important;
}

.source.tawln-active {
  border: 1px solid rgba(255, 255, 255, 0.45) !important;
  border-radius: 0px 10px 0px 0px !important;
}

.source.tawln-active::placeholder {
  color: #ffffff74 !important;
}
.csv-upload-button {
    border-radius: 0px 0px 10px 10px !important;
}
.home-inner-card {
    background: #101420 !important;
    /* padding: 2px; */
    border: 1px solid #b260f085 !important;
}
.inner-card-value {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}
.inner-card-topic {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
}
.approve-token-list {
    border-bottom: 1px #2a308b dashed;
    /* line-height: 35px; */
}
.contract-address {
    color: #646ea0;
    /* overflow: auto !important;
    margin-right: 10px !important; */
}
.send-value {
    color: #838aab;
}
.editor {
    counter-reset: line;
    border: 1px solid #009edd;
    border-radius: 10px 10px 0px 10px !important;
    /* height: 250px; */
    overflow-y: scroll !important;
  }
  
  .editor #codeArea {
    outline: none;
    padding-left: 60px !important;
    
  }
  
  .editor pre {
    padding-left: 60px !important;
  }
  
  .editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
    
  }
  .ant-select-dropdown {
    min-width: 120px !important; 
    /* width: 120px !important;  */
    /* left: -10px !important;  */
    /* top: 35px !important; */
  }
  .span-button {
    cursor: pointer;
    color: #009edd;
  }
  .ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon {
    color: #b160f0;
}
.ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon {
  color: #009edd;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #009edd;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #b160f0;
}
.desktop-left-margin {
  margin-left: 10px;
}
.desktop-right-margin {
  margin-right: 100px !important;
}
.ant-table-tbody {
  background-color: #101420;
}



@media (max-width: 768px) {
  .contract-address {
    overflow: auto !important;
    margin-right: 10px !important;
    /* font-size: 10px; */
}
  .desktop-left-margin {
    margin-left: 0px;
  }
  .desktop-right-margin {
    margin-right: 0px;
  }
  /* .ant-steps .ant-steps-item-title {
    font-size: 14px;
    line-height: 12px;
  }
  .ant-steps .ant-steps-item-description {
    font-size: 10px;
    line-height: 10px;
  } */
  .address-list-text {
    font-size: 11px;
  }
  .span-button {
    font-size: 11px;
  }
  .csv-upload-button {
    font-size: 10px;
  }
  .source {
    padding-left: 5px !important;
  }
  .source, input::placeholder {
    /* font-size: 10px !important; */
  }
  .source, input {
    /* line-height: 15px !important; */
  }
  .tawln-wrapper {
    display: block !important;
  }
  /* .ant-steps-vertical {
    display: flex;
    flex-direction: row !important;
  }
  .ant-steps-item-description {
    display: none;
  }
  .ant-steps-item-title {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-steps-item-title .ant-steps-item-icon {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-icon {
    float: inherit;
    margin-inline-end: 16px;
  } */
  /* .ant-steps-item-container > .ant-steps-item > .ant-steps-item-container-icon {
    border-color: transparent !important;
  } */
  /* .ant-steps-finish-icon {
    position: absolute;
    top: 9px !important;
    left: -8px !important;
  } */
  .text-area-with-line-number .number-line {
    display: block !important;
  }
  .tawln-wrapper {
    border: 1px solid rgba(255, 255, 255, 0.45) !important;
    border-radius: 10px 0px 0px 10px !important;
  }
  
  .source.tawln-active {
    border: 1px solid rgba(255, 255, 255, 0.45) !important;
    border-radius: 0px 10px 0px 0px !important;
  }
  
  .source.tawln-active::placeholder {
    color: #ffffff74 !important;
  }
  .mobile-top-margin {
    margin-top: 15px;
  }
  .mobile-top-margin-form {
    margin-top: 20px;
  }
  .ant-steps-item-description {
    display: none !important;
  }
  .ant-steps-item-title {
    /* margin-top: 30px !important;
    margin-left: -20px !important; */
    /* float: left !important; */
  }
}
/* @media only screen and (max-width: 767px) {
  .ant-steps-vertical {
    display: flex;
    flex-direction: row !important;
  }
} */
