.ant-menu{
  border-right: none !important;
}
.ant-menu-sub.ant-menu-inline {
  background: #0d0e14 !important;
}
.ant-menu .ant-menu-submenu-inline {
  /* background: red !important; */
  font-size: 15px;
}
.ant-menu-item-selected {
  border-radius: 3px !important;
  font-size: 15px;
}
/* .ant-menu-dark.ant-menu-inline .ant-menu-item {
  width: 120% !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  width: 120% !important;
} */
.ant-menu-item-selected {
  background-color: #2f308c !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item >.ant-menu-title-content {
  font-size: 15px;
}

/* @media only screen and (max-width: 1200px) {
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    width: 90% !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    width: 90% !important;
  }
} */